.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

body {
   background-color: white !important;
   overflow-x: hidden;
}

/* NAVBAR */
.navBar {
   padding: 10px;
   width: 100%;
   font-size: 35px;
   background-color: white;
   /* box-shadow: 5px 10px 5px rgb(199, 199, 199); */
}

.navBar-buttons {
   /* padding-left: 20%; */
   position: absolute;
   bottom: 5%;
   font-size: 15px;
}

.navBar-contentLink {
   padding: 10px;
   padding-left: 20px;
   padding-right: 20px;
   margin-right: 10px;
   margin-left: 10px;
   color: black;
   text-decoration: none;
   border-radius: 5px;

   transition: .2s;
   animation-timing-function: ease-in-out;
}

.navBar-contentLink:hover {
   box-shadow: 2px 3px 5px rgb(214, 214, 214);
}

.navbar-contactLink {
   padding: 10px;
   padding-left: 20px;
   padding-right: 20px;
   margin-right: 10px;
   color: black;
   text-decoration: none;
   border-radius: 5px;

   border: 1px solid green;

   transition: .2s;
   animation-timing-function: ease-in-out;
}

.navbar-contactLink:hover {
   cursor: pointer;
   background-color: green;
   color: white;
   box-shadow: 2px 3px 5px rgb(214, 214, 214);
}

.title {
   margin-left: 60%;
   text-align: right;
   background-size: 100% 100%;
   background-image: url('./static/MaxiWell_final1_cropped.jpg');
   width: 200px;
   height: 100px;
   z-index: 99;
}

.titleRow {
   width: 100%;
}

/* INTRO IMAGE */
.introImage {
   background-image: url('./static/water.jpg');
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   height: 450px;
   width: 101%;
} 

/* .introImage {
   background-image: url('./static/penguin.jpg');
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 50% 15%;
   height: 320px;
   width: 100%;
} */

/* IMAGE OVERLAY TEXT & SUB-TITLE */
.image-overlay {
   position: absolute;
   left: 50%;
   top: 80%;
   transform: translate(-50%, -300%);
   background-color: rgba(300, 300, 300, .8);
   padding-left: 10px;
   padding-right: 10px;
   font-size: 45px;
   font-weight: bold;
   border-radius: 10px;
}

.image-overlay-subtitle {
   position: absolute;
   left: 50%;
   top: 74%;
   transform: translate(-50%, -300%);
   background-color: rgba(300, 300, 300, .8);
   padding-left: 10px;
   padding-right: 10px;
   font-size: 20px;
   font-weight: bold;
   border-radius: 10px;
}

/* SERVICES PROVIDED */
.servicesProvided {
   padding: 20px;
   transition: .3s;
   font-size: 35px;
   width: 100%;
}

.servicesProvided p {
   font-size: 13px;
}


.servicesProvided-wrapper {
   width: 70%;
}

.servicesProvided-bgWrapper {
   margin-top: 40px;
   margin-bottom: 40px;
   background-color: rgb(250, 250, 250);
}

.servicesProvided-title {
   margin-left: 10px;
}

.expertise-title {
   text-align: left !important;
}

.hr-85 {
   width: 85%;
   margin: 0 auto;
   margin-bottom: 10px;
}

.hr-75 {
   width: 75%;
   margin: 0 auto;
   margin-bottom: 10px;
}

.hr-60 {
   width: 60%;
   margin: 0 auto;
   margin-bottom: 10px;
}

/* ABOUT ME */
.aboutMe-wrapper {
   width: 80%;
   padding-top: 15px;
   margin-top: 40px;
   padding-left: 80px;
   border-left: 10px solid rgb(90, 90, 252);
   background-color: rgb(250, 250, 250);
   border-radius: 50px;
   font-size: 20px;
   box-shadow: 2px 3px 5px rgb(200, 200, 200);
}

.aboutMe-iconWrapper {
   font-size: 125px;
   display: table;
   align-content: center;
   text-align: center;
}

.aboutMe-icon {
   font-size: 125px;
   display: table-cell;
   vertical-align: middle;
   align-self: center;
}

/* PAGE FOOTER */
.pageFooter {
   position: absolute;
   bottom: 2%;
   left: 50%;
   transform: translate(-50%, 50%);
   color: grey;
   background-color: white;
   font-size: 10px;
}

/* ############# MOBILE SIZING ############# */

/* All the above sizing is for large screens (1400px and greater) */

/* PHONES (500px and below) */
@media screen and (min-width: 300px) and (max-width: 901px) {

   /* Max Logo */
   .title {
      margin-left: 0px;
      text-align: right;
      background-size: 100% 100%;
      background-image: url('./static/MaxiWell_final1_cropped.jpg');
      width: 140px !important;
      height: 80px;
      z-index: 99;
   }

   /* NAVBAR */
   .navBar {
      padding: 10px;
      width: 100%;
      font-size: 35px;
      background-color: white;
      /* box-shadow: 5px 10px 5px rgb(199, 199, 199); */
   }

   .navBar-buttons {
      position: absolute;
      bottom: 5%;
      font-size: 12px;
   }

   .navBar-contentLink {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 20px;
      margin-left: 5px;
      color: white;
      background-color: rgb(125, 125, 255);
      text-decoration: none;
      border-radius: 5px;

      transition: .2s;
      animation-timing-function: ease-in-out;
   }

   .navBar-contentLink:hover {
      box-shadow: 2px 3px 5px rgb(214, 214, 214);
   }

   .navbar-contactLink {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 5px;
      color: white;
      background-color: rgb(64, 126, 64);
      text-decoration: none;
      border-radius: 5px;


      transition: .2s;
      animation-timing-function: ease-in-out;
   }

   .navbar-contactLink:hover {
      cursor: pointer;
      background-color: green;
      color: white;
      box-shadow: 2px 3px 5px rgb(214, 214, 214);
   }

   /* IMAGE OVERLAY TEXT & SUB-TITLE */
   .image-overlay {
      position: absolute;
      left: 50%;
      top: 80%;
      width: 250px;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 25px;
      font-weight: bold;
      border-radius: 10px;
      text-align: center;
   }

   .image-overlay-subtitle {
      position: absolute;
      left: 50%;
      top: 70%;
      width: 250px;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 15px;
      font-weight: bold;
      border-radius: 10px;
   }

   /* SERVICES PROVIDED */
   .expertise-title {
      text-align: center !important;
   }

   .expertise-title p {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 20px;
      text-align: left !important;
   }

   /* ABOUT ME */
   .aboutMe-wrapper {
      width: 100%;
      padding: 30px;
      margin-top: 40px;
      border: 0px solid black;
      background-color: rgb(250, 250, 250);
      font-size: 20px;
   }

   .aboutMe-iconWrapper {
      font-size: 125px;
      display: table;
      align-content: center;
      text-align: center;
   }

   .aboutMe-icon {
      font-size: 125px;
      display: table-cell;
      vertical-align: middle;
      align-self: center;
   }
}

/* Slightly Smaller Screens */
@media screen and (min-width: 1000px) and (max-width: 1350px) {
   /* IMAGE OVERLAY TEXT & SUB-TITLE */
   .image-overlay {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 30px;
      font-weight: bold;
      border-radius: 10px;
   }

   .image-overlay-subtitle {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 15px;
      font-weight: bold;
      border-radius: 10px;
   }
}

/* And Even Smaller */
@media screen and (min-width: 600px) and (max-width: 1002px) {
   /* IMAGE OVERLAY TEXT & SUB-TITLE */
   .image-overlay {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 28px;
      font-weight: bold;
      border-radius: 10px;
   }

   .image-overlay-subtitle {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 15px;
      font-weight: bold;
      border-radius: 10px;
   }

   /* Max Img */
   .title {
      margin-left: 10%;
      text-align: right;
      background-size: 100% 100%;
      background-image: url('./static/MaxiWell_final1_cropped.jpg');
      width: 200px;
      height: 100px;
      z-index: 99;
   }
}

/* And Even Even Smaller */
@media screen and (min-width: 600px) and (max-width: 860px) {
   /* IMAGE OVERLAY TEXT & SUB-TITLE */
   .image-overlay {
      position: absolute;
      left: 50%;
      top: 65%;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 20px;
      font-weight: bold;
      border-radius: 10px;
   }

   .image-overlay-subtitle {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translate(-50%, -300%);
      background-color: rgba(300, 300, 300, .8);
      padding-left: 10px;
      padding-right: 10px;
      font-size: 15px;
      font-weight: bold;
      border-radius: 10px;
   }

   /* Max Img */
   .title {
      margin-left: 10%;
      text-align: right;
      background-size: 100% 100%;
      background-image: url('./static/MaxiWell_final1_cropped.jpg');
      width: 200px;
      height: 100px;
      z-index: 99;
   }
}